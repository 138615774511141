

import { defineComponent, PropType, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
interface Profile {
  name: string
  email: string
  avatar: string
  roles: string
}

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<Profile>,
      default: () => {
        return {}
      }
    }
  },
  setup(props, _) {
    console.log(_)
    const dataMap = reactive({
      userInfo: props.user,
      submit: () => {
        ElMessage.success({
          message: 'User information has been updated successfully',
          type: 'success',
          duration: 5 * 1000
        })
      }

    })

    return { ...toRefs(dataMap) }
  }
})
// import { Component, Prop, Vue } from 'vue-property-decorator'
// import { IProfile } from '../index.vue'
// export default class extends Vue {
//   @Prop({ required: true }) private user!: IProfile

//   private submit() {
//     this.$message({
//       message: 'User information has been updated successfully',
//       type: 'success',
//       duration: 5 * 1000
//     })
//   }
// }
